.todo__item {
  display: flex;
  align-items: flex-start;

  & > label {
    margin-right: 1.25rem;
    flex-shrink: 0;
  }

  & > input[type=checkbox] {
    &:checked {
      & ~ .list-group__content {
        &, .list-group__attrs > span {
          text-decoration: line-through;
        }
      }
    }
  }
}