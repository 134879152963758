.sidebar.quickpanel
{
    opacity: 1;
    transform: unset;
    display: none;
    position: fixed;
    left: auto;
    right: 0;
    max-width: 35rem;

    &.active
    {
        display: block;
        z-index: 99;
        pointer-events: all;
    }

    &.sidebar--active
    {
        transform: unset;
    }
}

.quickpanel-backdrop
{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 98;
    cursor: pointer;
    background-color:rgba(0,0,0,.35);
}