.card {
  margin-bottom: $card-columns-margin;
  box-shadow: $card-shadow;

  .card {
    background-color: lighten($card-bg, 4%);
    box-shadow: none;
  }
}

.card-title {
  font-size: 1rem;
  line-height: 140%;
  margin-top: -3px;
  font-weight: $font-weight-normal;
  text-transform: uppercase;

  &:last-child {
    margin-bottom: 0;
  }
}

.card-subtitle {
  color: $theme-color-sub;
  font-size: 0.95rem;
  font-weight: normal;
  margin-top: -1.75rem;
  line-height: 1.5;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
}

.card > .actions,
.card-body .actions {
  position: absolute;
  right: 1.5rem;
  z-index: 2;
  top: 1.5rem;
}

[class*="card-img"] {
  width: 100%;
}

.card-link {
  text-transform: uppercase;
  font-size: 0.9rem;
  color: $text-muted;
  display: inline-block;
  margin-top: 1rem;
  transition: color 300ms;

  &:hover {
    color: $theme-color-heading;
  }
}

.card-body {
  .card-body {
    margin-bottom: 0;
  }
}

.card-body__title {
  font-size: 1.1rem;
  color: $headings-color;
  margin-bottom: 1rem;
  font-weight: normal;
}

.card-header,
.card-footer {
  padding: 1.2rem $card-spacer-x;
}

.card-group {
  box-shadow: $card-shadow;

  .card {
    box-shadow: none;
  }
}

.card--fullscreen {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  overflow: auto;
}