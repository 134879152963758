.uploader.rs-uploader.rs-uploader-draggable
{
    width: 100%;
    height: 100%;

    .rs-uploader-trigger
    {
        width: 100%;
        height: 100%;

        input[type='file']
        {
            width: 0;
            height: 0;
            padding: 0;
        }

        .rs-uploader-trigger-btn
        {
            width: 100%;
            height: 100%;
            border: 2px dashed #415969;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 0;
            background: transparent;
            transition: all 0.2s;

            i
            {
                font-size: 3.5rem;
                color: #415969;
                transition: all 0.2s;
            }

            &:hover
            {
                border: 2px dashed #6c8ea4;

                i
                {
                    color: #6c8ea4;
                }
            }
        }
    }
}

.uploadWrapper
{
    position: relative;
    max-width: 100%;

    .preview
    {
        max-width: unset;

        audio
        {
            width: 90%;
        }

        img
        {
            width: 100%;
        }

        .closeButton
        {
            position: absolute;
            width: 30px;
            height: 30px;
            top: -15px;
            right: -15px;
            cursor: pointer;
            background-color: #ffffff;
            border-radius: 50%;

            i
            {
                font-size: 2.2rem;
                font-weight: 700;
                color: #415969;
            }
        }

        .fileBackground
        {
            background-color: #C3C3C3;
            padding: 2rem 0;
            text-align: center;
            min-height: 300px;
            display: flex;
            align-items: center;
            justify-content: center;

            img
            {
                max-width: 100px;
            }

            .downloadButton
            {
                margin-top: 1.5rem;
                background-color: #1073b9;
                color: #fff;
                border: 0;
                border-radius: 0.5rem;
                padding: 0.5rem 3rem;

                a
                {
                    color: #fff;
                    text-decoration: none;

                    &:hover
                    {
                        color: #fff;
                    }
                }
            }
        }
    }
}