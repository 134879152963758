.rs-picker-daterange, .rs-picker-date
{
    width: 100%;

    .rs-picker-toggle
    {
        &.rs-btn
        {
            background-color: #2b3c46 !important;
            border: 1px solid #415969;
            border-color: #415969 !important;
            padding: .7rem 1rem;
        }

        .rs-picker-toggle-caret
        {
            display: none;
        }

        .rs-picker-toggle-placeholder, .rs-picker-toggle-caret, .rs-picker-toggle-value, .rs-picker-toggle-clean
        {
            color: #7996a9;
            font-size: 1rem;
            font-weight: 400;
            transition: color 0.3s;
        }

    }
}

.rs-picker-menu
{
    background-color: #1e2a31;
    z-index: 1000;

    .rs-picker-daterange-content
    {
        height: 360px;
    }

    .rs-calendar
    {
        background-color: #1e2a31;
        height: 310px;

        .rs-calendar-table-cell
        {
            padding: 5px;
            width: 2%;

            .rs-calendar-table-cell-content
            {
                border-radius: 50%;

                &:hover
                {
                    background-color: #394e5a;
                    color: #dcf3ff;
                }
            }

            &.rs-calendar-table-cell-is-today
            {
                padding: 5px;

                .rs-calendar-table-cell-content
                {
                    border: 1px solid #394e5a;
                }
            }

            &.rs-calendar-table-cell-in-range
            {
                &:before
                {
                    background-color: rgba(155, 188, 209, .5);
                    height: 26px;
                    top: 3px;
                }
            }
        }

        .rs-calendar-table-cell-disabled, .rs-calendar-table-cell-un-same-month
        {
            .rs-calendar-table-cell-content
            {
                color: rgba(121, 150, 169, .5);

                &:hover
                {
                    color: rgba(121, 150, 169, .5);
                }
            }
        }
    }

    .rs-calendar-table-cell-selected
    {
        &.rs-calendar-table-cell-in-range
        {
            &:before
            {
                display: none;
            }
        }

        .rs-calendar-table-cell-content
        {
            background-color: #394e5a;
            color: #dcf3ff;
        }
    }

    .rs-picker-toolbar
    {
        .rs-picker-toolbar-ranges
        {
            margin-top: 0;

            .rs-picker-toolbar-option
            {
                background-color: #2b3c46;
                color: #9bbcd1;
                border-radius: 1.5rem;
                margin-left: 1rem;
                padding: 0.2rem 0.8rem;
                margin-top: 0.1rem;
                text-decoration: none;

                &:hover
                {
                    background-color: rgba(155, 188, 209, .5)
                }
            }
        }
    }

    .rs-picker-toolbar-right
    {
        .rs-picker-toolbar-right-btn-ok
        {
            color: #9bbcd1;
            background-color: #2b3c46;
        }
    }

    &.fade.in
    {
        opacity: 1;
    }
}