.w-pictures__body {
  margin: 0;
  padding: 2px;
  text-align: center;
  @include clearfix();

  & > a {
    padding: 0;
    display: block;
    border: 3px solid $theme-dark-3;

    img {
      width: 100%;
      border-radius: $border-radius;
    }

    &:hover {
      opacity: 0.9;
    }
  }
}