.select2-container--default {
  .select2-selection--single {
    border-radius: $border-radius;
    background: $custom-select-background;
    border-color: $input-border-color;
    height: $input-height;

    .select2-selection__arrow {
      display: none;
    }

    .select2-selection__placeholder {
      color: $input-placeholder-color;
    }

    .select2-selection__rendered {
      padding: 0.4rem $input-btn-padding-x;
      color: $input-color;
    }
  }

  &.select2-container--focus {
    .select2-selection--multiple {
      border-color: $input-focus-border-color;
    }
  }

  .select2-selection--multiple {
    background-color: transparent;
    border-color: $input-border-color;
    border-radius: $border-radius;
    transition: border-color 300ms;

    .select2-selection__choice {
      border-radius: 1px;
      border: 0;
      background-color: $theme-light;
      padding: 0.35rem 0.75rem 0.32rem 1.5rem;
      color: $theme-invert-color;
      font-size: 0.9rem;
      position: relative;
    }

    .select2-selection__choice__remove {
      margin-right: 0.5rem;
      color: $theme-invert-color;
      font-weight: $font-weight-normal;
      font-size: 1.275rem;
      position: absolute;
      left: 0.5rem;
      top: 0;
    }
  }

  &.select2-container--disabled {
    .select2-selection--single {
      background-color: transparent;
      opacity: 0.75;
    }
  }

  .select2-results__option[aria-selected=true] {
    &, &:hover, &:focus {
      background-color: $dropdown-link-active-bg;
      color: $dropdown-link-active-color;
    }
  }
}

.select2-selection--single,
.select2-selection--multiple {
  transition: border-color 300ms;
}

.select2-container--open {
  .select2-selection--single {
    box-shadow: $input-focus-box-shadow;
    border-color: $input-focus-border-color;
  }
}

.select2-dropdown {
  background-color: $dropdown-bg;
  border: 0;
  border-radius: $border-radius;
  padding: 0.5rem;
  box-shadow: $dropdown-box-shadow;

  .select2-results__option {
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    color: $dropdown-link-color;
    border-radius: $border-radius;

    & + .select2-results__option {
      margin-top: 1px;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    &:not([aria-selected=true]) {
      background-color: $dropdown-link-hover-bg;
      color: $dropdown-link-hover-color;
    }
  }

  .select2-results__option[aria-disabled=true] {
    color: $dropdown-link-disabled-color;
  }

  .select2-search--dropdown {
    .select2-search__field {
      border: 0;
      background-color: transparent;
    }
  }
}

.select2-search__field {
  color: $theme-invert-color;

  @include placeholder() {
    color: $input-placeholder-color;
  }
}

.select2-container {
  .select2-search--inline {
    .select2-search__field {
      margin: 8px 0 2px;
      padding: 0 5px;
    }
  }
}