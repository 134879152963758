.accordion {
  .card {
    margin: 0 0 2px;
  }

  .card-header {
    background-color: lighten($card-bg, 6.5%);

    & > a {
      color: $headings-color;
      display: block;
      transition: color 300ms;

      &:hover {
        opacity: 0.9;
      }
    }
  }
}