.dataTable {
  .sorting,
  .sorting_asc,
  .sorting_desc {
    cursor: pointer;
    position: relative;
  }

  .sorting_asc,
  .sorting_desc {
    &:before {
      font-family: $font-family-icon;
      font-size: 1.25rem;
      position: absolute;
      bottom: 10px;
      right: 5px;
      opacity: 0;
      transition: opacity 300ms;
    }

    &:hover {
      &:before {
        opacity: 1;
      }
    }
  }

  .sorting_asc:before {
    content: '\e9e6';
  }

  .sorting_desc:before {
    content: '\e9e7';
  }
}


.dataTables__top {
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
}

.dataTables_length,
.dataTables_filter {
  font-size: 0;
  position: relative;
  appearance: none;

  &:after {
    font-family: $font-family-icon;
    position: absolute;
    left: 1rem;
    bottom: 0.5rem;
    font-size: 1.25rem;
    color: $theme-color-heading;
  }

  & > label {
    margin: 0;
    width: 100%;

    select,
    input[type=search] {
      padding: $input-padding-y $input-padding-x $input-padding-y $input-padding-x + 1.75;
      font-size: $font-size-base;
      border: 1px solid $input-border-color;
      height: $input-height;
      border-radius: $border-radius;
      width: 100%;
      background-color: $input-bg;
      color: $input-color;
      transition: border-color 300ms;

      &:focus {
        border-color: $input-focus-border-color;
        box-shadow: $input-focus-box-shadow;
      }

      @include placeholder {
        color: $input-placeholder-color;
      }
    }
  }
}

.dataTables_length {
  @include media-breakpoint-up(sm) {
    min-width: 150px;
    margin-left: 1.5rem;
  }

  @include media-breakpoint-down(xs) {
    display: none;
  }

  &:after {
    content: '\ea5b';
  }

  select {
    appearance: none;
    background: $select-bg;
  }
}

.dataTables_filter {
  flex: 1 1 auto;

  &:after {
    content: '\ea72';
  }
}

.dataTables_paginate {
  text-align: center;
}

.paginate_button {
  background-color: $pagination-bg;
  display: inline-block;
  color: $pagination-color;
  vertical-align: top;
  border-radius: 50%;
  margin: 0 1px 0 2px;
  font-size: 1rem;
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
  line-height: 2.5rem;
  text-align: center;
  transition: background-color 300ms, color 300ms;

  &.current {
    background-color: $pagination-active-bg;
    color: $pagination-active-color;
    cursor: default;
  }

  &:not(.current):not(.disabled) {
    &:hover,
    &:focus {
      background-color: $pagination-hover-bg;
      color: $pagination-color;
    }
  }

  &.current,
  &.disabled {
    cursor: default;
  }

  &.previous,
  &.next {
    font-size: 0;
    position: relative;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      font-size: 1rem;
    }

    &:before {
      font-family: $font-family-icon;
      font-size: 1.5rem;
      line-height: 2.55rem;
    }
  }

  &.previous {
    &:before {
      content: '\e90f';
    }
  }

  &.next {
    &:before {
      content: '\e910';
    }
  }

  &.disabled {
    opacity: 0.6;

    &:hover,
    &:focus {
      color: $pagination-color;
    }
  }
}

.dataTables_info {
  text-align: center;
  padding: 2.5rem 0 1.5rem;
  font-size: 0.9rem;
  color: $text-muted;
}

.data-table-toggled {
  overflow: hidden;

  .dataTables_buttons {
    [data-table-action="fullscreen"] {
      &:before {
        content: '\f16c';
      }
    }
  }
}

.data-table {
  .table > thead > tr > th {
    border-top: 0;
    user-select: none;
    cursor: pointer;
    position: relative;
  }
}

.data-table__header {
  padding: 0 0 2rem;
}

.data-table__filter {
  max-width: 500px;

  .form-control {
    padding-left: 2rem;
  }

  &:before {
    @include font-icon('\f1c3', 1.5rem);
    position: absolute;
    left: 0;
    bottom: 0.263rem;
  }
}

.data-table__footer {
  text-align: center;
  padding: $card-spacer-y 0;
}

// Action menu
.dt-buttons { // Hiding default buttons in favour of added custom action menu
  display: none;
}

.dataTables_actions {
  @include media-breakpoint-up(sm) {
    @include size(40px, $input-height);
    border:  1px solid $input-border-color;
    margin-left: 1.5rem;
    position: relative;
    border-radius: $border-radius;

    & > i {
      @include size(100%, 100%);
      font-size: 1.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      transition: color 300ms, background-color 300ms;
    }

    &.show > i,
    & > i:hover {
      background-color: $input-border-color;
      color: $theme-color-heading;
    }
  }

  @include media-breakpoint-down(xs) {
    display: none;
  }
}