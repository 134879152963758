.alerts
{
    position:fixed;
    width:25%;
    z-index:9999;
    right: 1.5rem;
    top: 2rem;
    display:none;

    &.active
    {
        display:block;
    }
}