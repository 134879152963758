.accordion
{
    .card-header
    {
        cursor: pointer;
        position: relative;

        &.removable
        {
            padding-right: 4.5rem;
        }

        .removeButton
        {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 3.3rem;
            text-align: center;
            justify-content: center;
            display: flex;
            //border-left: 2px solid #415969;
            align-items: center;
            -webkit-box-shadow: -4px 0px 15px -5px rgba(0, 0, 0, 0.3);
            box-shadow: -4px 0px 15px -5px rgba(0, 0, 0, 0.3);

            i
            {
                font-size: 1.8rem;
                font-weight: 400;
                margin-top: 2px;
            }
        }

        .actions
        {
            i
            {
                font-size: 2.5rem;
                font-weight: 700;
                margin-top: 2px;
            }

            .zwicon-chevron-up
            {
                display: none;
            }
        }

        &.active
        {
            .actions
            {
                .zwicon-chevron-up
                {
                    display: block;
                }

                .zwicon-chevron-down
                {
                    display: none;
                }
            }
        }
    }

    .card-body
    {
        .actions
        {
            top: 0.8rem;
        }
    }

    .collapse
    {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.15s ease-out;

        &:not(.show)
        {
            display: block;
        }

        &.show
        {
            max-height: 2000px;
            transition: max-height 0.25s ease-in;
        }

        .card-body
        {
            background-color: #2b3c46;
        }
    }
}