.page-loader.component-loader
{
    position:absolute;
    top:0;
    left:0;

    &.table-loader
    {
        background-color:#2b3c46;
    }

    .loader
    {
        text-align:left;
    }
}