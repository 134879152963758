.tag.rs-picker-tag
{
    padding: 0.25rem 0.5rem;
    background-color: transparent;
    color: #9bbcd1;
    border: 1px solid #415969;

    .rs-picker-tag-wrapper
    {
        .rs-tag
        {
            background-color: #394e5a;
            padding-top: 5px;
            padding-bottom: 3px;

            .rs-tag-text, .rs-btn-close
            {
                color: #fff;
                font-size: 13px;
            }

            .rs-btn-close
            {
                padding-top:8px;
            }
        }
    }

    &.rs-picker-focused, &:hover
    {
        border-color: #6c8ea4;

        .rs-picker-toggle
        {
            .rs-picker-toggle-caret
            {
                color: #9bbcd1;
            }
        }
    }

    .rs-picker-toggle
    {
        background: transparent !important;
        padding-left: 0.5rem;
        padding-right: 4rem;
        padding-top: 0.75rem;
        padding-bottom: 0.75rem;
        border: 1px solid #415969;

        .rs-picker-toggle-value
        {
            color: #9bbcd1;
            padding-left:0.8rem;
        }

        .rs-picker-toggle-textbox
        {
            border: 0;
            background-color: transparent;
            outline: none;
        }

        .rs-picker-toggle-caret
        {
            color: #7996a9;
        }

        .rs-picker-toggle-placeholder
        {
            color: #7996a9;
            margin-left: 0.8rem;
        }
    }

    .rs-btn
    {
        &.active
        {
            background-color: transparent;
        }
    }
}

.selectMenu.rs-picker-menu
{
    min-width: 200px !important;
    z-index: 100;

    .rs-picker-select-menu-item
    {
        color:#dcf3ff;

        &.rs-picker-select-menu-item-active
        {
            color:#dcf3ff;
            background-color: #3c3f43;

            &:hover
            {
                color:#dcf3ff;
                background-color: #3c3f43;
            }
        }

        &:hover
        {
            color:#dcf3ff;
            background-color: #3c3f43;
        }
    }
}