body
{
    .rs-picker-menu
    {
        &.fade.in
        {
            opacity: 1;
        }
    }
}

img
{
    width: 100%;
}

.content
{
    .content__title
    {
        padding-left: 0;
        padding-right: 0;
    }
}

.round-button
{
    border-radius: 2rem;
}

.btn-outline-theme
{
    color: #4d6979;
}

a:hover
{
    color: $theme-color-heading-sub;
}

section
{
    .stats
    {
        .stat__info
        {
            font-size: 3rem;
        }

        i
        {
            font-size: 4rem;
        }
    }
}

form
{
    .form-group
    {
        .checkbox-char
        {
            label
            {
                font-size: 0;
            }

            &.active
            {
                label
                {
                    background-color: #f0f6f9;

                    &:before
                    {
                        opacity: 1;
                    }
                }


            }
        }
    }
}

/*** GENERAL COLOR STYLES ***/

.colors
{
    &.light-1
    {
        color: $theme-light;
    }

    &.light-2
    {
        color: $theme-color-heading;
    }

    &.light-3
    {
        color: $theme-color-heading-sub;
    }

    &.light-4
    {
        color: $theme-color;
    }

    &.light-5
    {
        color: $theme-color-sub;
    }

    &.dark-1
    {
        color: $theme-border-color;
    }

    &.dark-2
    {
        color: $theme-dark-5;
    }

    &.dark-3
    {
        color: $theme-dark-4;
    }

    &.dark-4
    {
        color: $theme-dark-3;
    }

    &.dark-5
    {
        color: $theme-dark-2;
    }

    &.dark-6
    {
        color: $theme-dark-1;
    }

    &.purple
    {
        color: #ac66f5;
    }

    &.blue
    {
        color: #1c90f9;
    }

    &.yellow
    {
        color: #f6d907;
    }

    &.red
    {
        color: #f23e70;
    }

    &.green
    {
        color: #1fd5c6;
    }

    &.lightBlue
    {
        color: #7bd0ff;
    }

    &.orange
    {
        color: #f58c62;
    }

    &.deepGreen
    {
        color: #2cc56f;
    }

}

/*** GENERAL FONT STYLES ***/

.fonts
{
    &.font-48
    {
        font-size: 3rem;
    }

    &.font-40
    {
        font-size: 2.5rem;
    }

    &.font-32
    {
        font-size: 2rem;
    }

    &.font-28
    {
        font-size: 1.75rem;
    }

    &.font-24
    {
        font-size: 1.5rem;
    }

    &.font-22
    {
        font-size: 1.375rem;
    }

    &.font-20
    {
        font-size: 1.25rem;
    }

    &.font-18
    {
        font-size: 1.125rem;
    }

    &.font-16
    {
        font-size: 1rem;
    }

    &.font-14
    {
        font-size: 0.875rem;
    }

    &.font-12
    {
        font-size: 0.75rem;
    }

    &.font-10
    {
        font-size: 0.625rem;
    }

    &.weight-100
    {
        font-weight: 100;
    }

    &.weight-300
    {
        font-weight: 300;
    }

    &.weight-400
    {
        font-weight: 400;
    }

    &.weight-500
    {
        font-weight: 500;
    }

    &.weight-700
    {
        font-weight: 700;
    }

    &.weight-900
    {
        font-weight: 900;
    }
}

/*** GENERAL BUTTON STYLES ***/

.button
{
    &.btn
    {
        a
        {
            color: $theme-color-heading
        }
    }

    &.btn-sm
    {
        padding: 0.3rem 0.7rem;
    }

    &.round-button
    {
        border-radius: 2rem;
    }

    &.btn-outline-theme
    {
        color: #4d6979;
    }

    &.btn-outline-primary
    {
        a
        {
            color: #27a4fb;

            &:hover
            {
                color: #fff;
            }
        }

        &:hover
        {
            a
            {
                color: #fff;
            }
        }
    }
}

@media (min-width: 1200px)
{
    .content
    {
        padding-left: 5rem;
        padding-right: 5rem;

        &.with-sidebar
        {
            padding-left: 20rem;
            padding-right: 3rem;
        }
    }

    .logo
    {
        width: 30rem;

        &.with-sidebar
        {
            width: 20rem;
        }
    }
}