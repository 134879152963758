form
{
    .form-group
    {
        /*.preview
        {
            max-width: 6rem;
        }*/

        .form-control
        {
            &.is-invalid
            {
                background-position-x: 98%;
            }
        }
    }
}