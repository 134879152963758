.tab-container.tabPane
{
    .nav-tabs
    {
        .nav-item
        {
            cursor: pointer;
            position:relative;

            .removeTab
            {
                position:absolute;
                font-size:2rem;
                right:0;
                top:9px;
            }
        }
    }
}