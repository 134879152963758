.login
{
    .login__header
    {
        img
        {
            width: 50px;
            height: auto;
            border-radius: 0;
            margin-bottom: 0;
        }
    }
}

.login__btn
{
    &:focus
    {
        outline: none;
    }
}

.invalid-feedback
{
    margin-top: 1rem;

    &:first-child
    {
        margin-top: 2rem;
    }
}