.modal-title {
  color: $theme-invert-color;
  font-size: 1rem;
  text-transform: uppercase;
}

.modal-footer {
  padding-top: 0;

  & > .btn-link {
    color: $modal-content-color;
    cursor: pointer;

    &:hover,
    &:focus {
      background-color: $modal-footer-link-hover-bg;
      color: $theme-invert-color;
    }
  }
}

.modal-footer--alt {
  display: flex;
  background-color: darken($theme-light, 5%);
  padding: 0;
  justify-content: center;
  margin-top: 1rem;

  & > .btn-link {
    margin: 0;
    text-transform: uppercase;
    font-size: 0.9rem;
    font-weight: $font-weight-bold;
    height: 45px;

    &:hover {
      background-color: darken($theme-light, 8.5%);
    }

    &:focus {
      background-color: darken($theme-light, 12%);
    }
  }
}