.error {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  height: 100vh;
  width: 100%;
}

.error__inner {
  max-width: 600px;
  width: 100%;
  padding: 4rem;
  text-align: center;
  border-radius: $border-radius-lg;
  background-color: $card-bg;
  box-shadow: $card-shadow;

  & > h1 {
    font-size: 6rem;
    font-weight: $font-weight-light;
    line-height: 100%;
    margin: 0;

    & > span {
      transform: scale(-1, 1);
      display: inline-block;
    }
  }

  & > h2 {
    font-weight: normal;
    margin: 1rem 0;
    font-size: 1.25rem;
  }

  & > p {
    color: $theme-color-sub;
  }
}