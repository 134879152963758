.easy-pie-chart {
  display: inline-block;
  position: relative;
}

.easy-pie-chart__value {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  line-height: 100%;
  padding-bottom: 4px;
  @include flex-aligned();

  &:after {
    content: "%";
    font-size: 1rem;
  }
}