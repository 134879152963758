.rs-btn {
    --rs-btn-default-bg: #394e5a;
    --rs-btn-default-text: #b7d3e4;
    --rs-btn-default-active-bg: #394e5a;
    --rs-btn-default-active-text: #b7d3e4;
    --rs-btn-default-hover-bg: #394e5a;
    --rs-btn-default-hover-text: #b7d3e4;

    border-radius: 0;

    &:focus {
        outline: none;
    }
}
