.sidebar
{
    .sidebar__header
    {
        .title
        {
            font-size: 1.2rem;
            font-weight: 400;
            text-transform: uppercase;
            margin: 0;
        }

        .subtitle
        {
            font-size: .95rem;
            color: #7996a9;
            text-transform: none;
            display: block;
            margin-top: .25rem;
        }
    }

    .navigation__menu
    {
        .blue
        {
            color: #27a4fb;
        }

        .yellow
        {
            color: #ffc021;
        }

        .red
        {
            color: #f93964;
        }

        .green
        {
            color: #02dbc5;
        }
    }

    .navigation__sub
    {
        a
        {
            text-decoration: unset;
        }

        &.active
        {
            > ul
            {
                display: block;
                max-height: 80vh;
            }
        }

        > ul
        {
            max-height: 0;
            overflow: hidden;
            transition: max-height 0.25s ease-in-out;
            padding-left: 2rem;
            display: block;

            > .navigation__sub
            {
                > ul
                {
                    padding-left: 1rem;
                }
            }

            > li
            {
                > a
                {
                    border-radius: .25rem;
                    font-size: .95rem;
                    display: flex;
                    align-items: center;
                    padding: .8rem 1rem .7rem;

                    > i
                    {
                        font-size: 1.5rem;
                        margin-right: 1rem;
                        position: relative;
                        opacity: .65;
                        -webkit-transition: opacity .3s;
                        transition: opacity .3s;
                        top: -1px;
                    }

                    &:hover
                    {
                        > i
                        {
                            opacity: 1;
                        }
                    }

                    &.active:before
                    {
                        content: none;
                    }
                }
            }
        }
    }
}

.mainmenu-backdrop
{
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    cursor: pointer;
    background-color: rgba(0, 0, 0, .35);
    display: none;
    opacity: 0;
    transition: opacity .3s;

    &.active
    {
        display: block;
        opacity: 1;
    }
}