.rs-input-group.rs-input-number
{
    background-color: transparent;
    border: 0;

    input
    {
        color:#9bbcd1;
    }

    &:hover
    {
        border: 1px solid #6c8ea4;
    }

    &.rs-input-group-focus
    {
        border: 1px solid #6c8ea4;
    }

    .rs-input
    {
        border: 1px solid #415969;
        background-color: transparent;
        border-radius: 0.5rem;

        &:focus
        {
            border: 1px solid #6c8ea4;
        }
    }

    .rs-input-number-btn-group-vertical
    {
        display: none;
    }

    .rs-btn
    {
        border: 0;
        display: none;

        &.rs-input-number-touchspin-up
        {
            border-top: 1px solid #415969;
            border-right: 1px solid #415969;

            &:hover, &:focus
            {
                border: 0;
            }
        }

        &.rs-input-number-touchspin-down
        {
            border-bottom: 1px solid #415969;
            border-right: 1px solid #415969;

            &:hover, &:focus
            {
                border: 0;
            }
        }

        &:hover, &:focus
        {
            background-color: transparent;
            border: 0;
        }
    }

}