.w-calendar {
  & > .actions {
    margin-top: 1rem;
  }

  td, th {
    border-color: transparent;
    text-align: center;
  }

  .fc-toolbar {
    h2 {
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: $font-weight-normal;
    }
  }

  .fc-day-number {
    text-align: center;
    width: 100%;
    padding: 0;
  }
}

#w-calendar-body {
  padding: 1rem;

  .fc-center {
    display: flex;
    align-items: center;
  }

  .fc-button {
    color: $theme-color-sub;
    transition: color 300ms, background-color 300ms;

    &:hover {
      color: $theme-color-heading;
    }
  }
}

.w-calendar__header {
  padding: $card-spacer-y $card-spacer-x;
  background-color: $theme-dark-4;
  border-radius: $border-radius $border-radius 0 0;
}

.w-calendar__year {
  font-size: 1.2rem;
  line-height: 100%;
  margin-bottom: 0.6rem;
  color: $theme-color;
}

.w-calendar__day {
  font-size: 1.5rem;
  line-height: 100%;
  color: $theme-color-heading;
}