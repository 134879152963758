// Color, Background color and Border color
@each $color, $value in $extra-colors {
  $value-lighten: lighten($value, 12%);

  .bg-#{$color} {
    background-color: $value !important;
  }

  .bg-gradient-#{$color} {
    background: linear-gradient(90deg, #{$value} 0, #{$value-lighten} 100%) !important;
  }

  .text-#{$color} {
    color: $value !important;
  }

  .text-active-#{$color} {
    &.active {
      color: $value !important;
    }
  }

  .border-#{$color} {
    border-color: rgba($value, 0.65) !important;
  }
}

.text-invert {
  color: $theme-invert-color;
}