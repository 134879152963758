.tableContainer {
    font-family: Roboto;

    .rs-table-scrollbar {
        opacity: 0;
        transition: all 0.3s;
    }

    &:hover {
        .rs-table-scrollbar {
            opacity: 1;
        }
    }

    .select.pageLength {
        .rs-btn.rs-picker-toggle {
            background-color: transparent !important;
            padding-left: 1.25rem;
            padding-right: 4rem;
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            border: 1px solid #415969;

            .rs-picker-toggle-caret {
                top: 10px;
            }
        }
    }

    .filter {
        width: 50%;

        @media (max-width: 1199px) {
            width: 100%;
        }
    }

    .pageSearch {
        position: relative;
        width: 100%;

        input {
            padding: 0.5rem 1rem 0.5rem 2.75rem;
            font-size: 1rem;
            border: 1px solid #415969;
            height: 40px;
            border-radius: 0.25rem;
            width: 100%;
            background-color: transparent;
            color: #6c8ea4;
            -webkit-transition: border-color 0.3s;
            transition: border-color 0.3s;

            &::placeholder {
                /* Chrome, Firefox, Opera, Safari 10.1+ */
                -webkit-transition: color 0.3s;
                transition: color 0.3s;
                color: #415969;
                opacity: 1; /* Firefox */
            }

            &:-ms-input-placeholder {
                /* Internet Explorer 10-11 */
                -webkit-transition: color 0.3s;
                transition: color 0.3s;
                color: #415969;
            }

            &:-ms-input-placeholder {
                /* Microsoft Edge */
                -webkit-transition: color 0.3s;
                transition: color 0.3s;
                color: #415969;
            }

            &:focus {
                border: 1px solid #6c8ea4;

                &::placeholder {
                    /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: #6c8ea4;
                    opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder {
                    /* Internet Explorer 10-11 */
                    color: #6c8ea4;
                }

                &:-ms-input-placeholder {
                    /* Microsoft Edge */
                    color: #6c8ea4;
                }
            }
        }

        &:after {
            font-family: zwicon;
            content: "\EA72";
            position: absolute;
            left: 1rem;
            top: 0.5rem;
            font-size: 1.25rem;
            color: #415969;
        }

        &:focus {
            &:after {
                color: #6c8ea4;
            }
        }
    }

    /*.tableHeader
    {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        margin-bottom: 1.5rem;

        .filter
        {
            -webkit-box-flex: 1;
            flex: 1 1 auto;
            font-size: 0;
            appearance: none;
            position: relative;

            label
            {
                width: 100%;
            }

            .tableSearch
            {
                padding: .5rem 1rem .5rem 2.75rem;
                font-size: 1rem;
                border: 1px solid #415969;
                height: 40px;
                border-radius: .25rem;
                width: 100%;
                background-color: transparent;
                color: #6c8ea4;
                -webkit-transition: border-color .3s;
                transition: border-color .3s;

                &::placeholder
                { !* Chrome, Firefox, Opera, Safari 10.1+ *!
                    -webkit-transition: color .3s;
                    transition: color .3s;
                    color: #415969;
                    opacity: 1; !* Firefox *!
                }

                &:-ms-input-placeholder
                { !* Internet Explorer 10-11 *!
                    -webkit-transition: color .3s;
                    transition: color .3s;
                    color: #415969;
                }

                &:-ms-input-placeholder
                {
                    -webkit-transition: color .3s;
                    transition: color .3s;
                    color: #415969;
                }

                &:focus
                {
                    border: 1px solid #6c8ea4;

                    &::placeholder
                    {
                        color: #6c8ea4;
                        opacity: 1;
                    }

                    &:-ms-input-placeholder
                    {
                        color: #6c8ea4;
                    }

                    &:-ms-input-placeholder
                    {
                        color: #6c8ea4;
                    }
                }
            }

            &:after
            {
                font-family: zwicon;
                content: '\EA72';
                position: absolute;
                left: 1rem;
                top: .5rem;
                font-size: 1.25rem;
                color: #6c8ea4;
            }

            &:focus
            {
                &:after
                {
                    color: #6c8ea4;
                }
            }
        }

        .pageLength
        {
            font-size: 0;
            position: relative;
            appearance: none;

            @media (min-width: 576px)
            {
                min-width: 150px;
                margin-left: 1.5rem;
            }

            label
            {
                width: 100%;
            }

            &:after
            {
                font-family: zwicon;
                content: '\EA5b';
                position: absolute;
                left: 1rem;
                top: .5rem;
                font-size: 1.25rem;
                color: #dcf3ff;
            }

            select
            {
                padding: .5rem 1rem .5rem 2.75rem;
                font-size: 1rem;
                border: 1px solid #415969;
                height: 40px;
                border-radius: .25rem;
                width: 100%;
                background-color: transparent;
                color: #9bbcd1;
                -webkit-transition: border-color .3s;
                transition: border-color .3s;
                -webkit-appearance: none;
                appearance: none;

                &:focus
                {
                    border-color: #6c8ea4;
                }
            }
        }
    }*/

    .rs-table-header-row-wrapper {
        .rs-table-row-header {
            background-color: transparent;
            border-bottom: 1px solid #394e5a;

            .rs-table-cell-group-fixed-right {
                background-color: #2b3c46;
            }

            .rs-table-cell-group {
                .rs-table-column-resize-spanner {
                    border: 0;
                }
            }

            .rs-table-cell {
                background-color: transparent;
                padding: 1rem;

                .rs-table-cell-content {
                    padding: 0;
                    font-size: 1rem;
                    color: #dcf3ff;
                    display: block;
                    vertical-align: top !important;
                }
            }
        }
    }

    .rs-table-body-row-wrapper {
        .rs-table-row {
            border-bottom: 1px solid #394e5a;

            .rs-table-cell-group-fixed-right {
                background-color: #2b3c46;
            }

            .rs-table-cell {
                background-color: transparent;
            }
        }
    }

    .rs-table-row:not(.rs-table-row-header):hover {
        background-color: transparent;
    }
}
