.modal-backdrop
{
    background-color: rgba(0, 0, 0, .35);
    display: none;
    z-index:98;

    &.active
    {
        display: block;
    }
}

.modal
{
    display: none;
    z-index:99;

    .modal-title
    {
        color:#dcf3ff;
        font-size:1.2rem;
    }

    .modal-content
    {
        background-color:#2b3c46;
        color:#9bbcd1;

        .closeButton
        {
            position: absolute;
            font-size: 2.5rem;
            top: 1.4rem;
            right: 1.2rem;
            cursor:pointer;
        }
    }

    &.active
    {
        display: block;
    }

    .modal-header
    {
        .close
        {
            padding: 0;
            margin: 0;
        }
    }

    .modal-body
    {
        //height:80vh;
        /*overflow-y: auto;*/
        max-height: 80vh;

        .btn-link
        {
            color: #436c84;
            cursor: pointer;

            &:hover
            {
                background-color: #dce4e9;
                color: #36576b;
            }
        }
    }

    .modalLoader
    {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #f0f6f9;
        border-radius: .3rem;
    }
}