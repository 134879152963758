@font-face {
  font-family: 'zwicon';
  src:  url('fonts/zwicon.eot?k483k8');
  src:  url('fonts/zwicon.eot?k483k8#iefix') format('embedded-opentype'),
    url('fonts/zwicon.ttf?k483k8') format('truetype'),
    url('fonts/zwicon.woff?k483k8') format('woff'),
    url('fonts/zwicon.svg?k483k8#zwicon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class*="zwicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'zwicon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.zwicon-align-bottom:before {
  content: "\e900";
}
.zwicon-align-h:before {
  content: "\e901";
}
.zwicon-align-left:before {
  content: "\e902";
}
.zwicon-align-right:before {
  content: "\e903";
}
.zwicon-align-top:before {
  content: "\e904";
}
.zwicon-align-v:before {
  content: "\e905";
}
.zwicon-distribute-h:before {
  content: "\e906";
}
.zwicon-distribute-v:before {
  content: "\e907";
}
.zwicon-arrow-bottom-left:before {
  content: "\e908";
}
.zwicon-arrow-bottom-right:before {
  content: "\e909";
}
.zwicon-arrow-circle-down:before {
  content: "\e90a";
}
.zwicon-arrow-circle-left:before {
  content: "\e90b";
}
.zwicon-arrow-circle-right:before {
  content: "\e90c";
}
.zwicon-arrow-circle-up:before {
  content: "\e90d";
}
.zwicon-arrow-down:before {
  content: "\e90e";
}
.zwicon-arrow-left:before {
  content: "\e90f";
}
.zwicon-arrow-right:before {
  content: "\e910";
}
.zwicon-arrow-square-down:before {
  content: "\e911";
}
.zwicon-arrow-square-left:before {
  content: "\e912";
}
.zwicon-arrow-square-right:before {
  content: "\e913";
}
.zwicon-arrow-square-up:before {
  content: "\e914";
}
.zwicon-arrow-to-top:before {
  content: "\e915";
}
.zwicon-arrow-top-left:before {
  content: "\e916";
}
.zwicon-arrow-top-right:before {
  content: "\e917";
}
.zwicon-arrow-up:before {
  content: "\e918";
}
.zwicon-back:before {
  content: "\e919";
}
.zwicon-chevron-double-down:before {
  content: "\e91a";
}
.zwicon-chevron-double-left:before {
  content: "\e91b";
}
.zwicon-chevron-double-right:before {
  content: "\e91c";
}
.zwicon-chevron-double-up:before {
  content: "\e91d";
}
.zwicon-chevron-down:before {
  content: "\e91e";
}
.zwicon-chevron-left:before {
  content: "\e91f";
}
.zwicon-chevron-right:before {
  content: "\e920";
}
.zwicon-chevron-up:before {
  content: "\e921";
}
.zwicon-collapse-alt:before {
  content: "\e922";
}
.zwicon-collapse-alt2:before {
  content: "\e923";
}
.zwicon-collapse-down:before {
  content: "\e924";
}
.zwicon-collapse-left:before {
  content: "\e925";
}
.zwicon-collapse-right:before {
  content: "\e926";
}
.zwicon-collapse-up:before {
  content: "\e927";
}
.zwicon-collapse:before {
  content: "\e928";
}
.zwicon-continue:before {
  content: "\e929";
}
.zwicon-expand-alt:before {
  content: "\e92a";
}
.zwicon-expand-alt2:before {
  content: "\e92b";
}
.zwicon-expand-down:before {
  content: "\e92c";
}
.zwicon-expand-h:before {
  content: "\e92d";
}
.zwicon-expand-left:before {
  content: "\e92e";
}
.zwicon-expand-right:before {
  content: "\e92f";
}
.zwicon-expand-up:before {
  content: "\e930";
}
.zwicon-expand-v:before {
  content: "\e931";
}
.zwicon-expand:before {
  content: "\e932";
}
.zwicon-loop:before {
  content: "\e933";
}
.zwicon-prioritize-down:before {
  content: "\e934";
}
.zwicon-prioritize-up:before {
  content: "\e935";
}
.zwicon-redo:before {
  content: "\e936";
}
.zwicon-refresh-double:before {
  content: "\e937";
}
.zwicon-refresh-left:before {
  content: "\e938";
}
.zwicon-refresh-right:before {
  content: "\e939";
}
.zwicon-restart:before {
  content: "\e93a";
}
.zwicon-split-h:before {
  content: "\e93b";
}
.zwicon-split-v:before {
  content: "\e93c";
}
.zwicon-undo:before {
  content: "\e93d";
}
.zwicon-cell-border-bottom:before {
  content: "\e93e";
}
.zwicon-cell-border-full:before {
  content: "\e93f";
}
.zwicon-cell-border-left:before {
  content: "\e940";
}
.zwicon-cell-border-right:before {
  content: "\e941";
}
.zwicon-cell-border-top:before {
  content: "\e942";
}
.zwicon-cell-empty:before {
  content: "\e943";
}
.zwicon-cell-full:before {
  content: "\e944";
}
.zwicon-cell-split-h:before {
  content: "\e945";
}
.zwicon-cell-split-v:before {
  content: "\e946";
}
.zwicon-cell-split:before {
  content: "\e947";
}
.zwicon-archive:before {
  content: "\e948";
}
.zwicon-document:before {
  content: "\e949";
}
.zwicon-folder-delete:before {
  content: "\e94a";
}
.zwicon-folder-minus:before {
  content: "\e94b";
}
.zwicon-folder-open:before {
  content: "\e94c";
}
.zwicon-folder-plus:before {
  content: "\e94d";
}
.zwicon-folder:before {
  content: "\e94e";
}
.zwicon-note:before {
  content: "\e94f";
}
.zwicon-notebook:before {
  content: "\e950";
}
.zwicon-script:before {
  content: "\e951";
}
.zwicon-sticker:before {
  content: "\e952";
}
.zwicon-sticky-notes:before {
  content: "\e953";
}
.zwicon-tray-delete:before {
  content: "\e954";
}
.zwicon-tray-empty:before {
  content: "\e955";
}
.zwicon-tray-export:before {
  content: "\e956";
}
.zwicon-tray-import:before {
  content: "\e957";
}
.zwicon-tray-minus:before {
  content: "\e958";
}
.zwicon-tray-plus:before {
  content: "\e959";
}
.zwicon-tray-stack:before {
  content: "\e95a";
}
.zwicon-tray:before {
  content: "\e95b";
}
.zwicon-artboard:before {
  content: "\e95c";
}
.zwicon-brush:before {
  content: "\e95d";
}
.zwicon-clipboard:before {
  content: "\e95e";
}
.zwicon-copy-alt:before {
  content: "\e95f";
}
.zwicon-copy:before {
  content: "\e960";
}
.zwicon-crop:before {
  content: "\e961";
}
.zwicon-cut-alt:before {
  content: "\e962";
}
.zwicon-cut:before {
  content: "\e963";
}
.zwicon-drafting-compass:before {
  content: "\e964";
}
.zwicon-duplicate-alt:before {
  content: "\e965";
}
.zwicon-duplicate:before {
  content: "\e966";
}
.zwicon-eraser:before {
  content: "\e967";
}
.zwicon-eye-dropper:before {
  content: "\e968";
}
.zwicon-group:before {
  content: "\e969";
}
.zwicon-layer-stack:before {
  content: "\e96a";
}
.zwicon-magic-wand:before {
  content: "\e96b";
}
.zwicon-marker:before {
  content: "\e96c";
}
.zwicon-paint-bucket:before {
  content: "\e96d";
}
.zwicon-paint-roller:before {
  content: "\e96e";
}
.zwicon-palette:before {
  content: "\e96f";
}
.zwicon-paste-alt:before {
  content: "\e970";
}
.zwicon-paste:before {
  content: "\e971";
}
.zwicon-pen-circle:before {
  content: "\e972";
}
.zwicon-pen:before {
  content: "\e973";
}
.zwicon-pencil-circle:before {
  content: "\e974";
}
.zwicon-pencil:before {
  content: "\e975";
}
.zwicon-ruler-combined:before {
  content: "\e976";
}
.zwicon-ruler-h:before {
  content: "\e977";
}
.zwicon-ruler-v:before {
  content: "\e978";
}
.zwicon-stamp:before {
  content: "\e979";
}
.zwicon-table:before {
  content: "\e97a";
}
.zwicon-activity:before {
  content: "\e97b";
}
.zwicon-android:before {
  content: "\e97c";
}
.zwicon-apple:before {
  content: "\e97d";
}
.zwicon-bolt:before {
  content: "\e97e";
}
.zwicon-cloud-download:before {
  content: "\e97f";
}
.zwicon-cloud-minus:before {
  content: "\e980";
}
.zwicon-cloud-plus:before {
  content: "\e981";
}
.zwicon-cloud-upload:before {
  content: "\e982";
}
.zwicon-code:before {
  content: "\e983";
}
.zwicon-command:before {
  content: "\e984";
}
.zwicon-database:before {
  content: "\e985";
}
.zwicon-deploy:before {
  content: "\e986";
}
.zwicon-git-branch:before {
  content: "\e987";
}
.zwicon-git-commit:before {
  content: "\e988";
}
.zwicon-git-fork:before {
  content: "\e989";
}
.zwicon-git-merge:before {
  content: "\e98a";
}
.zwicon-git-pull:before {
  content: "\e98b";
}
.zwicon-ios:before {
  content: "\e98c";
}
.zwicon-lan-connection:before {
  content: "\e98d";
}
.zwicon-lan-error:before {
  content: "\e98e";
}
.zwicon-lan:before {
  content: "\e98f";
}
.zwicon-osx:before {
  content: "\e990";
}
.zwicon-repository:before {
  content: "\e991";
}
.zwicon-web:before {
  content: "\e992";
}
.zwicon-window-delete:before {
  content: "\e993";
}
.zwicon-window-minus:before {
  content: "\e994";
}
.zwicon-window-plus:before {
  content: "\e995";
}
.zwicon-window:before {
  content: "\e996";
}
.zwicon-windows:before {
  content: "\e997";
}
.zwicon-airpods-alt:before {
  content: "\e998";
}
.zwicon-airpods:before {
  content: "\e999";
}
.zwicon-apple-watch-smile:before {
  content: "\e99a";
}
.zwicon-apple-watch-time:before {
  content: "\e99b";
}
.zwicon-apple-watch:before {
  content: "\e99c";
}
.zwicon-cable-hdmi:before {
  content: "\e99d";
}
.zwicon-cable-jack:before {
  content: "\e99e";
}
.zwicon-cable-lan:before {
  content: "\e99f";
}
.zwicon-cable-lightning:before {
  content: "\e9a0";
}
.zwicon-cable-magsafe:before {
  content: "\e9a1";
}
.zwicon-cable-usb:before {
  content: "\e9a2";
}
.zwicon-cardboard-vr:before {
  content: "\e9a3";
}
.zwicon-controller-alt:before {
  content: "\e9a4";
}
.zwicon-controller:before {
  content: "\e9a5";
}
.zwicon-desktop:before {
  content: "\e9a6";
}
.zwicon-devices:before {
  content: "\e9a7";
}
.zwicon-floppy:before {
  content: "\e9a8";
}
.zwicon-gameboy:before {
  content: "\e9a9";
}
.zwicon-hard-drive:before {
  content: "\e9aa";
}
.zwicon-headphone:before {
  content: "\e9ab";
}
.zwicon-imac:before {
  content: "\e9ac";
}
.zwicon-ipad-h:before {
  content: "\e9ad";
}
.zwicon-ipad:before {
  content: "\e9ae";
}
.zwicon-iphone-h:before {
  content: "\e9af";
}
.zwicon-iphone-x-h:before {
  content: "\e9b0";
}
.zwicon-iphone-x:before {
  content: "\e9b1";
}
.zwicon-iphone:before {
  content: "\e9b2";
}
.zwicon-keyboard:before {
  content: "\e9b3";
}
.zwicon-laptop:before {
  content: "\e9b4";
}
.zwicon-mac-pro:before {
  content: "\e9b5";
}
.zwicon-macbook-pro:before {
  content: "\e9b6";
}
.zwicon-memory-card:before {
  content: "\e9b7";
}
.zwicon-mouse:before {
  content: "\e9b8";
}
.zwicon-phone-andorid-h:before {
  content: "\e9b9";
}
.zwicon-phone-andorid:before {
  content: "\e9ba";
}
.zwicon-phone-holding-double:before {
  content: "\e9bb";
}
.zwicon-phone-holding:before {
  content: "\e9bc";
}
.zwicon-plug:before {
  content: "\e9bd";
}
.zwicon-printer:before {
  content: "\e9be";
}
.zwicon-server-stack:before {
  content: "\e9bf";
}
.zwicon-smart-glasses:before {
  content: "\e9c0";
}
.zwicon-smart-tv:before {
  content: "\e9c1";
}
.zwicon-smart-watch-time:before {
  content: "\e9c2";
}
.zwicon-smart-watch:before {
  content: "\e9c3";
}
.zwicon-tablet-h:before {
  content: "\e9c4";
}
.zwicon-tablet:before {
  content: "\e9c5";
}
.zwicon-terminal:before {
  content: "\e9c6";
}
.zwicon-virtual-reality:before {
  content: "\e9c7";
}
.zwicon-voice-assistant:before {
  content: "\e9c8";
}
.zwicon-edit-circle:before {
  content: "\e9c9";
}
.zwicon-edit-pencil:before {
  content: "\e9ca";
}
.zwicon-edit-square-feather:before {
  content: "\e9cb";
}
.zwicon-edit-square:before {
  content: "\e9cc";
}
.zwicon-file-archive:before {
  content: "\e9cd";
}
.zwicon-file-audio:before {
  content: "\e9ce";
}
.zwicon-file-cloud:before {
  content: "\e9cf";
}
.zwicon-file-download:before {
  content: "\e9d0";
}
.zwicon-file-empty:before {
  content: "\e9d1";
}
.zwicon-file-export:before {
  content: "\e9d2";
}
.zwicon-file-font:before {
  content: "\e9d3";
}
.zwicon-file-graphic:before {
  content: "\e9d4";
}
.zwicon-file-image:before {
  content: "\e9d5";
}
.zwicon-file-import:before {
  content: "\e9d6";
}
.zwicon-file-pdf:before {
  content: "\e9d7";
}
.zwicon-file-search:before {
  content: "\e9d8";
}
.zwicon-file-sketch:before {
  content: "\e9d9";
}
.zwicon-file-table:before {
  content: "\e9da";
}
.zwicon-file-upload:before {
  content: "\e9db";
}
.zwicon-file-vector:before {
  content: "\e9dc";
}
.zwicon-file-video:before {
  content: "\e9dd";
}
.zwicon-filter-alt:before {
  content: "\e9de";
}
.zwicon-filter:before {
  content: "\e9df";
}
.zwicon-slider-circle-h:before {
  content: "\e9e0";
}
.zwicon-slider-circle-v:before {
  content: "\e9e1";
}
.zwicon-slider-rectangle-h:before {
  content: "\e9e2";
}
.zwicon-slider-rectangle-v:before {
  content: "\e9e3";
}
.zwicon-sort-alphabetic-down:before {
  content: "\e9e4";
}
.zwicon-sort-alphabetic-up:before {
  content: "\e9e5";
}
.zwicon-sort-amount-down:before {
  content: "\e9e6";
}
.zwicon-sort-amount-up:before {
  content: "\e9e7";
}
.zwicon-sort-numeric-down:before {
  content: "\e9e8";
}
.zwicon-sort-numeric-up:before {
  content: "\e9e9";
}
.zwicon-toggle-switch:before {
  content: "\e9ea";
}
.zwicon-bar-code-scan:before {
  content: "\e9eb";
}
.zwicon-bar-code:before {
  content: "\e9ec";
}
.zwicon-bid:before {
  content: "\e9ed";
}
.zwicon-bill:before {
  content: "\e9ee";
}
.zwicon-bitcoin-sign:before {
  content: "\e9ef";
}
.zwicon-bull-horn:before {
  content: "\e9f0";
}
.zwicon-coin:before {
  content: "\e9f1";
}
.zwicon-credit-card:before {
  content: "\e9f2";
}
.zwicon-diamond:before {
  content: "\e9f3";
}
.zwicon-dollar-sign:before {
  content: "\e9f4";
}
.zwicon-euro-sign:before {
  content: "\e9f5";
}
.zwicon-hammer:before {
  content: "\e9f6";
}
.zwicon-line-chart:before {
  content: "\e9f7";
}
.zwicon-lira-sign:before {
  content: "\e9f8";
}
.zwicon-money-bill:before {
  content: "\e9f9";
}
.zwicon-money-stack:before {
  content: "\e9fa";
}
.zwicon-package:before {
  content: "\e9fb";
}
.zwicon-piggy-bank:before {
  content: "\e9fc";
}
.zwicon-pound-sign:before {
  content: "\e9fd";
}
.zwicon-price-tag:before {
  content: "\e9fe";
}
.zwicon-qr-code-scan:before {
  content: "\e9ff";
}
.zwicon-qr-code:before {
  content: "\ea00";
}
.zwicon-receipt:before {
  content: "\ea01";
}
.zwicon-rubel-sign:before {
  content: "\ea02";
}
.zwicon-rupee-sign:before {
  content: "\ea03";
}
.zwicon-sale-badge:before {
  content: "\ea04";
}
.zwicon-shopping-bag-alt:before {
  content: "\ea05";
}
.zwicon-shopping-bag:before {
  content: "\ea06";
}
.zwicon-shopping-cart:before {
  content: "\ea07";
}
.zwicon-store:before {
  content: "\ea08";
}
.zwicon-wallet:before {
  content: "\ea09";
}
.zwicon-won-sign:before {
  content: "\ea0a";
}
.zwicon-yen-sign:before {
  content: "\ea0b";
}
.zwicon-flip-left-alt:before {
  content: "\ea0c";
}
.zwicon-flip-left:before {
  content: "\ea0d";
}
.zwicon-flip-right-alt:before {
  content: "\ea0e";
}
.zwicon-flip-right:before {
  content: "\ea0f";
}
.zwicon-double-tap-two:before {
  content: "\ea10";
}
.zwicon-double-tap:before {
  content: "\ea11";
}
.zwicon-drag:before {
  content: "\ea12";
}
.zwicon-flick-left-two:before {
  content: "\ea13";
}
.zwicon-flick-left:before {
  content: "\ea14";
}
.zwicon-flick-right-two:before {
  content: "\ea15";
}
.zwicon-flick-right:before {
  content: "\ea16";
}
.zwicon-horns:before {
  content: "\ea17";
}
.zwicon-pinch:before {
  content: "\ea18";
}
.zwicon-point:before {
  content: "\ea19";
}
.zwicon-press:before {
  content: "\ea1a";
}
.zwicon-scroll-down-two:before {
  content: "\ea1b";
}
.zwicon-scroll-down:before {
  content: "\ea1c";
}
.zwicon-scroll-h-two:before {
  content: "\ea1d";
}
.zwicon-scroll-h:before {
  content: "\ea1e";
}
.zwicon-scroll-up-two:before {
  content: "\ea1f";
}
.zwicon-scroll-up:before {
  content: "\ea20";
}
.zwicon-scroll-v-two:before {
  content: "\ea21";
}
.zwicon-scroll-v:before {
  content: "\ea22";
}
.zwicon-shaka:before {
  content: "\ea23";
}
.zwicon-spread:before {
  content: "\ea24";
}
.zwicon-tap-two:before {
  content: "\ea25";
}
.zwicon-tap:before {
  content: "\ea26";
}
.zwicon-two-drag:before {
  content: "\ea27";
}
.zwicon-add-item-alt:before {
  content: "\ea28";
}
.zwicon-add-item:before {
  content: "\ea29";
}
.zwicon-add-note:before {
  content: "\ea2a";
}
.zwicon-add-to-list:before {
  content: "\ea2b";
}
.zwicon-at:before {
  content: "\ea2c";
}
.zwicon-attach-document:before {
  content: "\ea2d";
}
.zwicon-paperclip:before {
  content: "\ea2e";
}
.zwicon-battery-full:before {
  content: "\ea30";
}
.zwicon-battery-low:before {
  content: "\ea31";
}
.zwicon-battery-mid:before {
  content: "\ea32";
}
.zwicon-battery-v:before {
  content: "\ea33";
}
.zwicon-bell-alt-ring:before {
  content: "\ea34";
}
.zwicon-bell-alt:before {
  content: "\ea35";
}
.zwicon-bell-slash:before {
  content: "\ea36";
}
.zwicon-bell-snooze:before {
  content: "\ea37";
}
.zwicon-bell:before {
  content: "\ea38";
}
.zwicon-block:before {
  content: "\ea39";
}
.zwicon-book-alt:before {
  content: "\ea3a";
}
.zwicon-book:before {
  content: "\ea3b";
}
.zwicon-bookmark:before {
  content: "\ea3c";
}
.zwicon-briefcase:before {
  content: "\ea3d";
}
.zwicon-calendar-day:before {
  content: "\ea3e";
}
.zwicon-calendar-month:before {
  content: "\ea3f";
}
.zwicon-calendar-never:before {
  content: "\ea40";
}
.zwicon-calendar-week:before {
  content: "\ea41";
}
.zwicon-calendar:before {
  content: "\ea42";
}
.zwicon-call-in:before {
  content: "\ea43";
}
.zwicon-call-out:before {
  content: "\ea44";
}
.zwicon-chat:before {
  content: "\ea45";
}
.zwicon-checkmark-circle:before {
  content: "\ea46";
}
.zwicon-checkmark-square:before {
  content: "\ea47";
}
.zwicon-checkmark:before {
  content: "\ea48";
}
.zwicon-clock:before {
  content: "\ea49";
}
.zwicon-close-circle:before {
  content: "\ea4a";
}
.zwicon-close-square:before {
  content: "\ea4b";
}
.zwicon-close:before {
  content: "\ea4c";
}
.zwicon-cog:before {
  content: "\ea4d";
}
.zwicon-comment:before {
  content: "\ea4e";
}
.zwicon-compass:before {
  content: "\ea4f";
}
.zwicon-delete:before {
  content: "\ea50";
}
.zwicon-download:before {
  content: "\ea51";
}
.zwicon-earth-alt:before {
  content: "\ea52";
}
.zwicon-earth:before {
  content: "\ea53";
}
.zwicon-exclamation-triangle:before {
  content: "\ea54";
}
.zwicon-exclamation-mark:before {
  content: "\ea2f";
}
.zwicon-export:before {
  content: "\ea55";
}
.zwicon-eye-slash:before {
  content: "\ea56";
}
.zwicon-eye:before {
  content: "\ea57";
}
.zwicon-face-id:before {
  content: "\ea58";
}
.zwicon-flag:before {
  content: "\ea59";
}
.zwicon-grid:before {
  content: "\ea5a";
}
.zwicon-hamburger-menu:before {
  content: "\ea5b";
}
.zwicon-heart:before {
  content: "\ea5c";
}
.zwicon-home:before {
  content: "\ea5d";
}
.zwicon-import:before {
  content: "\ea5e";
}
.zwicon-info-circle:before {
  content: "\ea5f";
}
.zwicon-lifebelt:before {
  content: "\ea60";
}
.zwicon-link:before {
  content: "\ea61";
}
.zwicon-lock-alt:before {
  content: "\ea62";
}
.zwicon-lock:before {
  content: "\ea63";
}
.zwicon-mail:before {
  content: "\ea64";
}
.zwicon-map-marker:before {
  content: "\ea65";
}
.zwicon-minus-circle:before {
  content: "\ea66";
}
.zwicon-minus-square:before {
  content: "\ea67";
}
.zwicon-minus:before {
  content: "\ea68";
}
.zwicon-more-h:before {
  content: "\ea69";
}
.zwicon-more-v:before {
  content: "\ea6a";
}
.zwicon-my-location:before {
  content: "\ea6b";
}
.zwicon-password:before {
  content: "\ea6c";
}
.zwicon-phone:before {
  content: "\ea6d";
}
.zwicon-pin:before {
  content: "\ea6e";
}
.zwicon-plus-circle:before {
  content: "\ea6f";
}
.zwicon-plus-square:before {
  content: "\ea70";
}
.zwicon-plus:before {
  content: "\ea71";
}
.zwicon-search:before {
  content: "\ea72";
}
.zwicon-send:before {
  content: "\ea73";
}
.zwicon-share:before {
  content: "\ea74";
}
.zwicon-shortcut:before {
  content: "\ea75";
}
.zwicon-sign-in:before {
  content: "\ea76";
}
.zwicon-sign-out:before {
  content: "\ea77";
}
.zwicon-thumbs-down:before {
  content: "\ea78";
}
.zwicon-thumbs-up:before {
  content: "\ea79";
}
.zwicon-trash:before {
  content: "\ea7a";
}
.zwicon-unlink:before {
  content: "\ea7b";
}
.zwicon-upload:before {
  content: "\ea7c";
}
.zwicon-user-circle:before {
  content: "\ea7d";
}
.zwicon-user-delete:before {
  content: "\ea7e";
}
.zwicon-user-follow:before {
  content: "\ea7f";
}
.zwicon-user-minus:before {
  content: "\ea80";
}
.zwicon-user-plus:before {
  content: "\ea81";
}
.zwicon-user:before {
  content: "\ea82";
}
.zwicon-users:before {
  content: "\ea83";
}
.zwicon-history:before {
  content: "\ea84";
}
.zwicon-task:before {
  content: "\ea85";
}
.zwicon-bottom-bar:before {
  content: "\ea86";
}
.zwicon-content-left:before {
  content: "\ea87";
}
.zwicon-content-right:before {
  content: "\ea88";
}
.zwicon-desktop-1:before {
  content: "\ea89";
}
.zwicon-desktop-2:before {
  content: "\ea8a";
}
.zwicon-desktop-3:before {
  content: "\ea8b";
}
.zwicon-half-h:before {
  content: "\ea8c";
}
.zwicon-half-v:before {
  content: "\ea8d";
}
.zwicon-layout-1:before {
  content: "\ea8e";
}
.zwicon-layout-2:before {
  content: "\ea8f";
}
.zwicon-layout-3:before {
  content: "\ea90";
}
.zwicon-layout-4:before {
  content: "\ea91";
}
.zwicon-layout-5:before {
  content: "\ea92";
}
.zwicon-left-bar:before {
  content: "\ea93";
}
.zwicon-margin:before {
  content: "\ea94";
}
.zwicon-right-bar:before {
  content: "\ea95";
}
.zwicon-sidebar:before {
  content: "\ea96";
}
.zwicon-three-h:before {
  content: "\ea97";
}
.zwicon-three-v:before {
  content: "\ea98";
}
.zwicon-to-bottom:before {
  content: "\ea99";
}
.zwicon-to-left:before {
  content: "\ea9a";
}
.zwicon-to-right:before {
  content: "\ea9b";
}
.zwicon-to-top:before {
  content: "\ea9c";
}
.zwicon-top-bar:before {
  content: "\ea9d";
}
.zwicon-airplay:before {
  content: "\ea9e";
}
.zwicon-broadcast:before {
  content: "\ea9f";
}
.zwicon-camera-alt:before {
  content: "\eaa0";
}
.zwicon-camera-alt2:before {
  content: "\eaa1";
}
.zwicon-camera:before {
  content: "\eaa2";
}
.zwicon-cast:before {
  content: "\eaa3";
}
.zwicon-collapse-wide:before {
  content: "\eaa4";
}
.zwicon-collapse1:before {
  content: "\eaa5";
}
.zwicon-disk:before {
  content: "\eaa6";
}
.zwicon-expand-wide:before {
  content: "\eaa7";
}
.zwicon-expand1:before {
  content: "\eaa8";
}
.zwicon-film-alt:before {
  content: "\eaa9";
}
.zwicon-film-play:before {
  content: "\eaaa";
}
.zwicon-film:before {
  content: "\eaab";
}
.zwicon-image-circle:before {
  content: "\eaac";
}
.zwicon-image-gallery:before {
  content: "\eaad";
}
.zwicon-image-wide:before {
  content: "\eaae";
}
.zwicon-image:before {
  content: "\eaaf";
}
.zwicon-microphone-mute:before {
  content: "\eab0";
}
.zwicon-microphone:before {
  content: "\eab1";
}
.zwicon-next-alt:before {
  content: "\eab2";
}
.zwicon-next:before {
  content: "\eab3";
}
.zwicon-panorama-h:before {
  content: "\eab4";
}
.zwicon-pause-alt:before {
  content: "\eab5";
}
.zwicon-pause:before {
  content: "\eab6";
}
.zwicon-play-alt:before {
  content: "\eab7";
}
.zwicon-play:before {
  content: "\eab8";
}
.zwicon-previous-alt:before {
  content: "\eab9";
}
.zwicon-previous:before {
  content: "\eaba";
}
.zwicon-shuffle:before {
  content: "\eabb";
}
.zwicon-video-alt:before {
  content: "\eabc";
}
.zwicon-video-camera:before {
  content: "\eabd";
}
.zwicon-video:before {
  content: "\eabe";
}
.zwicon-volume-low:before {
  content: "\eabf";
}
.zwicon-volume-max:before {
  content: "\eac0";
}
.zwicon-volume-mid:before {
  content: "\eac1";
}
.zwicon-volume-min:before {
  content: "\eac2";
}
.zwicon-wide-angle:before {
  content: "\eac3";
}
.zwicon-exclude:before {
  content: "\eac4";
}
.zwicon-flatten:before {
  content: "\eac5";
}
.zwicon-intersect:before {
  content: "\eac6";
}
.zwicon-substract-back:before {
  content: "\eac7";
}
.zwicon-substract-front:before {
  content: "\eac8";
}
.zwicon-unite:before {
  content: "\eac9";
}
.zwicon-height:before {
  content: "\eaca";
}
.zwicon-resize-alt:before {
  content: "\eacb";
}
.zwicon-resize:before {
  content: "\eacc";
}
.zwicon-scale-down:before {
  content: "\eacd";
}
.zwicon-scale-up:before {
  content: "\eace";
}
.zwicon-scale:before {
  content: "\eacf";
}
.zwicon-width:before {
  content: "\ead0";
}
.zwicon-rotate-axis-x:before {
  content: "\ead1";
}
.zwicon-rotate-axis-xy:before {
  content: "\ead2";
}
.zwicon-rotate-axis-y:before {
  content: "\ead3";
}
.zwicon-rotate-left:before {
  content: "\ead4";
}
.zwicon-rotate-right:before {
  content: "\ead5";
}
.zwicon-rotate-shape:before {
  content: "\ead6";
}
.zwicon-cursor-square:before {
  content: "\ead7";
}
.zwicon-cursor:before {
  content: "\ead8";
}
.zwicon-select-cursor:before {
  content: "\ead9";
}
.zwicon-select:before {
  content: "\eada";
}
.zwicon-shape-circle:before {
  content: "\eadb";
}
.zwicon-shape-cone:before {
  content: "\eadc";
}
.zwicon-shape-cube:before {
  content: "\eadd";
}
.zwicon-shape-cylinder:before {
  content: "\eade";
}
.zwicon-shape-octagonal:before {
  content: "\eadf";
}
.zwicon-shape-polygon:before {
  content: "\eae0";
}
.zwicon-shape-sphere:before {
  content: "\eae1";
}
.zwicon-shape-square:before {
  content: "\eae2";
}
.zwicon-laugh:before {
  content: "\eae3";
}
.zwicon-neutral:before {
  content: "\eae4";
}
.zwicon-sad:before {
  content: "\eae5";
}
.zwicon-smile:before {
  content: "\eae6";
}
.zwicon-bold:before {
  content: "\eae7";
}
.zwicon-draw-text-field:before {
  content: "\eae8";
}
.zwicon-font-height:before {
  content: "\eae9";
}
.zwicon-font-size:before {
  content: "\eaea";
}
.zwicon-font-width:before {
  content: "\eaeb";
}
.zwicon-font:before {
  content: "\eaec";
}
.zwicon-heading:before {
  content: "\eaed";
}
.zwicon-indent-left-alt:before {
  content: "\eaee";
}
.zwicon-indent-left:before {
  content: "\eaef";
}
.zwicon-indent-right-alt:before {
  content: "\eaf0";
}
.zwicon-indent-right:before {
  content: "\eaf1";
}
.zwicon-italic:before {
  content: "\eaf2";
}
.zwicon-list-bullet:before {
  content: "\eaf3";
}
.zwicon-list-number:before {
  content: "\eaf4";
}
.zwicon-outdent-left:before {
  content: "\eaf5";
}
.zwicon-outdent-right:before {
  content: "\eaf6";
}
.zwicon-paragraph:before {
  content: "\eaf7";
}
.zwicon-text-align-center:before {
  content: "\eaf8";
}
.zwicon-text-align-justify:before {
  content: "\eaf9";
}
.zwicon-text-align-left:before {
  content: "\eafa";
}
.zwicon-text-align-right:before {
  content: "\eafb";
}
.zwicon-text-cursor:before {
  content: "\eafc";
}
.zwicon-text-decoration:before {
  content: "\eafd";
}
.zwicon-text-field:before {
  content: "\eafe";
}
.zwicon-text:before {
  content: "\eaff";
}
.zwicon-underline:before {
  content: "\eb00";
}
.zwicon-wrap-img-left:before {
  content: "\eb01";
}
.zwicon-wrap-img-right:before {
  content: "\eb02";
}
.zwicon-wrap-left:before {
  content: "\eb03";
}
.zwicon-wrap-right:before {
  content: "\eb04";
}
.zwicon-transform-left:before {
  content: "\eb05";
}
.zwicon-transform-right:before {
  content: "\eb06";
}
.zwicon-ab-testing:before {
  content: "\eb07";
}
.zwicon-agile:before {
  content: "\eb08";
}
.zwicon-backlog:before {
  content: "\eb09";
}
.zwicon-design-studio:before {
  content: "\eb0a";
}
.zwicon-design-validation:before {
  content: "\eb0b";
}
.zwicon-information-architecture:before {
  content: "\eb0c";
}
.zwicon-interview:before {
  content: "\eb0d";
}
.zwicon-kanban-board:before {
  content: "\eb0e";
}
.zwicon-lego-serious-play:before {
  content: "\eb0f";
}
.zwicon-paper-prototype:before {
  content: "\eb10";
}
.zwicon-persona:before {
  content: "\eb11";
}
.zwicon-prototype-mobile:before {
  content: "\eb12";
}
.zwicon-prototype:before {
  content: "\eb13";
}
.zwicon-responsive:before {
  content: "\eb14";
}
.zwicon-screen-flow:before {
  content: "\eb15";
}
.zwicon-stand-up:before {
  content: "\eb16";
}
.zwicon-sticky-notes1:before {
  content: "\eb17";
}
.zwicon-usability:before {
  content: "\eb18";
}
.zwicon-user-flow:before {
  content: "\eb19";
}
.zwicon-user-interview:before {
  content: "\eb1a";
}
.zwicon-user-journey:before {
  content: "\eb1b";
}
.zwicon-cloud:before {
  content: "\eb1c";
}
.zwicon-cloudy-day:before {
  content: "\eb1d";
}
.zwicon-cloudy-night:before {
  content: "\eb1e";
}
.zwicon-heavy-rain-day:before {
  content: "\eb1f";
}
.zwicon-heavy-rain-night:before {
  content: "\eb20";
}
.zwicon-heavy-rain:before {
  content: "\eb21";
}
.zwicon-heavy-wind:before {
  content: "\eb22";
}
.zwicon-mild-rain-day:before {
  content: "\eb23";
}
.zwicon-mild-rain-night:before {
  content: "\eb24";
}
.zwicon-mild-rain:before {
  content: "\eb25";
}
.zwicon-moon:before {
  content: "\eb26";
}
.zwicon-rain-day:before {
  content: "\eb27";
}
.zwicon-rain-night:before {
  content: "\eb28";
}
.zwicon-rain:before {
  content: "\eb29";
}
.zwicon-snow-day:before {
  content: "\eb2a";
}
.zwicon-snow-night:before {
  content: "\eb2b";
}
.zwicon-snow:before {
  content: "\eb2c";
}
.zwicon-storm-day:before {
  content: "\eb2d";
}
.zwicon-storm-night:before {
  content: "\eb2e";
}
.zwicon-storm:before {
  content: "\eb2f";
}
.zwicon-sun:before {
  content: "\eb30";
}
.zwicon-temperature:before {
  content: "\eb31";
}
.zwicon-wind-alt:before {
  content: "\eb32";
}
.zwicon-wind-cloudy-day:before {
  content: "\eb33";
}
.zwicon-wind-cloudy-night:before {
  content: "\eb34";
}
.zwicon-wind-cloudy:before {
  content: "\eb35";
}
.zwicon-wind:before {
  content: "\eb36";
}
