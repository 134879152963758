.toolbar {
  display: flex;
  flex-direction: row;
  height: 4.5rem;
  align-items: center;
  padding: 0.05rem $card-spacer-x 0;
  position: relative;

  &:not(.toolbar--inner) {
    background-color: $card-bg;
    border-radius: $border-radius;
    margin-bottom: $grid-gutter-width;
    box-shadow: $card-shadow;
  }

  .actions {
    margin: 0.05rem -0.8rem 0 auto;
  }
}

.toolbar--inner {
  border-radius: $border-radius $border-radius 0 0;
  background-color: lighten($card-bg, 2.5%);

  .toolbar__search {
    border-radius: $border-radius $border-radius 0 0;
  }
}

.toolbar--alt {
  background-color: $theme-dark-2;
}

.toolbar__nav {
  white-space: nowrap;
  overflow-x: auto;

  & > a {
    color: $text-muted;
    display: inline-block;
    transition: color 300ms;

    & + a {
      padding-left: 1rem;
    }

    &:hover {
      color: $body-color;
    }

    &.active {
      color: $theme-color-heading;
    }
  }
}

.toolbar__search {
  position: absolute;
  left: 0;
  top: 0;
  @include size(100%, 100%);
  border-radius: $border-radius;
  padding-left: 3rem;
  display: none;
  background-color: $theme-dark-4;

  input[type=text] {
    width: 100%;
    height: 100%;
    border: 0;
    padding: 0 1.6rem;
    font-size: 1rem;
    background-color: transparent;
    color: $input-color;

    @include placeholder() {
      color: $input-placeholder-color;
    }
  }
}

.toolbar__search__close {
  transition: color 300ms;
  cursor: pointer;
  position: absolute;
  top: 1.2rem;
  left: 1.8rem;
  font-size: 2rem;
  color: lighten($body-color, 15%);

  &:hover {
    color: $body-color;
  }
}

.toolbar__label {
  margin: 0;
  font-size: 1.1rem;
}