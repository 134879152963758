.rs-pagination-group
{
    .rs-pagination
    {
        .rs-pagination-btn
        {
            padding: 8px 5px;
            line-height: 18px;
            min-width: 35px;

            &.rs-pagination-btn-active
            {
                color:#dcf3ff;
                border-color:#dcf3ff;
            }
        }
    }
}