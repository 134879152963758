.rs-picker {
    --rs-input-bg: #415969;
    --rs-picker-value: #ffffff;
}

.rs-picker-input {
    border: 0;
}

.rs-picker-select-menu {
    --rs-text-primary: #ffffff;
    --rs-listbox-option-hover-bg: #415969;
    --rs-listbox-option-hover-text: #ffffff;
    --rs-listbox-option-selected-bg: #415969;
    --rs-listbox-option-selected-text: #ffffff;
}

.rs-picker-select-menu-item.rs-picker-select-menu-item-active {
    font-weight: 400;
}

.rs-picker-select-menu-items {
    margin-top: 6px;
}
